import FooterComponent from "../FooterComponent/footerComponent";
import NavbarComponent from "../NavbarComponent/navbarComponent";
import React, {useEffect, useState} from "react";
import ContactUs from "../ContactUs/contactUs";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";
import projectsData from "../Models/projects.json";
import * as Icon from "react-feather";

const ContentWriting = () => {

    const [projects, setProjects] = useState([]);
    const [projectType, setProjectType] = useState("app");

    const filterProjects = () => {
        const filterData = projectsData.filter(each => each.project_tag === projectType)
        setProjects(filterData);
    }

    useEffect(() => {
        setProjects(projectsData);
        filterProjects();
        document.querySelector("body").scrollTo(0, 0);
    }, []);

    useEffect(() => {
        filterProjects();
    }, [projectType])


    return (
        <div className="container-fluid">
            <div className="row bg-interface-color justify-content-center align-items-center font-lato">
                <div className="col-12">
                    {/*navbar*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center mt-4 pb-4 mb-md-4">
                        <div className="col-md-10 col-11 mb-4 pb-md-2">
                            <div className="row">
                                <div className="col-12 text-center pt-2 pt-md-4 mt-md-4">
                                    <div className="">
                                        <label className="font-size-56 font-weight-700 font-georgia">
                                            Content writing
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 mt-md-4 mt-2">
                                    <label
                                        className="d-flex align-items-center justify-content-center text-align-center font-size-24 font-weight-400 text-color-464646 res-flex-column res-text-align-center">
                                        Whether you need to rewrite an essay, find a SEO content writer, or hire a
                                        freelance content writer, we’ve got the expertise to deliver high-quality
                                        content that will rank you higher on search engines.Let us handle it for you! We
                                        provide affordable social media management packages and Instagram management
                                        services to help you grow your online presence.
                                    </label>
                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className="col-12 ">

                                    <div className={"row"}>
                                        <div className={"col-12 justify-content-center align-items-center"}>
                                            <div className={"w-100 d-flex align-items-center gap-3"}>
                                                <button type={"button"}
                                                        onClick={() => setProjectType("app")}
                                                        className={`${projectType === "app" ? 'bg-indigo text-white border-0' : 'border-grey-3 text-color-black bg-transparent'}  px-3 py-2 font-lato font-size-16 font-weight-400 br-78`}>
                                                    Apps
                                                </button>
                                                <button type={"button"}
                                                        onClick={() => setProjectType("website")}
                                                        className={`${projectType === "website" ? 'bg-indigo text-white border-0' : 'border-grey-3 text-color-black bg-transparent'}  px-3 py-2 font-lato font-size-16 font-weight-400 br-78`}>
                                                    Websites
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {projects.map((eachProject, index) => (
                                        <div
                                            className={`row mt-md-5 mt-4 pt-md-2 ${index % 2 === 0 ? '' : 'flex-md-row-reverse'} align-items-center justify-content-between`}
                                            key={index}>
                                            <div className={"col-md-6"}>
                                                {eachProject.project_images.length > 1 ? (
                                                    <Swiper
                                                        autoplay={{
                                                            delay: index % 2 === 0 ? 1 : 0,
                                                            disableOnInteraction: false,
                                                        }}
                                                        speed={index % 2 === 0 ? 1500 : 2000}
                                                        modules={[Autoplay]}
                                                        loop={true}
                                                        spaceBetween={20}
                                                        grabCursor={true}
                                                        slidesPerView={1}
                                                    >
                                                        {eachProject.project_images.map((eachImage, index) => (
                                                            <SwiperSlide key={index}>
                                                                <img className="img-fluid br-10 w-100"
                                                                     src={eachImage.image_url}
                                                                     alt={eachImage.alt}/>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                ) : (
                                                    <div>
                                                        <img className="img-fluid br-10 w-100"
                                                             src={eachProject.project_images[0].image_url}
                                                             alt={eachProject.project_images[0].alt}/>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"col-md-6 mt-4 mt-md-0"}>
                                                <div className={"w-100"}>
                                                    <label
                                                        className="font-size-32 font-georgia font-weight-700">{eachProject.project_name}</label>
                                                    <div className={"mt-3"}>
                                                        <label
                                                            className="text-color-464646 font-lato font-size-16 font-weight-400">
                                                            {eachProject.project_description}
                                                        </label>
                                                    </div>
                                                    <div className={"mt-4 w-100"}>
                                                        <ul className={"d-flex flex-wrap w-100 justify-content-start gap-3 p-0 technologies-list"}>
                                                            {eachProject.project_technologies.map((each, index) => (
                                                                <li className={"border br-8 px-3 py-2"} key={index}>
                                                                    <label
                                                                        className={"font-size-14 font-weight-400 text-color-black"}>{each}</label>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        {eachProject.project_tag === "website" && (
                                                            <div className={"mt-3"}>
                                                                <a href={eachProject.project_url} rel="noreferrer"
                                                                   target={"_blank"}
                                                                   className={"font-size-14 font-weight-400"}
                                                                   style={{textDecoration: "none"}}>
                                                                    Know more <Icon.ArrowRight size={20}/>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/*<div className="row ">*/}
                                    {/*    <div className="col-12 col-md-6 position-relative works-beebloom-preview-image">*/}
                                    {/*        <img className="img-fluid br-10 w-100" id="works-beebloom-preview-image"*/}
                                    {/*             src={"images/landing/our-works-beebloom-image.png"} alt="Beebloom"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white font-size-32 font-weight-700">Beebloom</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                A user-friendly app that rewards the users for building healthy*/}
                                    {/*                habits.*/}
                                    {/*                It was selected among the top 30 Google AppScale Academy projects in*/}
                                    {/*                2023.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div*/}
                                    {/*        className="col-12 col-md-6 position-relative works-event-replay-preview-image pt-3 pt-md-0">*/}
                                    {/*        <img className="img-fluid bg-images-linear-gradient w-100 br-10"*/}
                                    {/*             id="works-event-replay-preview-image"*/}
                                    {/*             src={"images/landing/our-works-event-reply-display-image.png"}*/}
                                    {/*             alt="event replay"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white font-size-32 font-weight-700">Eventreply</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                A platform for creating and managing events. Both website and app*/}
                                    {/*                combo*/}
                                    {/*                simplifies*/}
                                    {/*                event organization, ensuring a seamless experience for both hosts*/}
                                    {/*                and*/}
                                    {/*                attendees.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row mt-3">*/}
                                    {/*    <div className="col-12 col-md-6 position-relative works-dating-preview-image">*/}
                                    {/*        <img className="img-fluid br-10 w-100" id="works-dating-preview-image"*/}
                                    {/*             src={"images/landing/our-works-dating-display-image.png"}*/}
                                    {/*             alt="dating"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white font-size-32 font-weight-700">Dating</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                A modern platform (App, Website) designed to help users find a*/}
                                    {/*                partner*/}
                                    {/*                nearby, set, and engage in activities together, turning shared*/}
                                    {/*                passions into*/}
                                    {/*                unforgettable dates.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div*/}
                                    {/*        className="col-12 col-md-6 position-relative works-fuel-desk-preview-image pt-md-0 pt-3">*/}
                                    {/*        <img className="img-fluid br-10 w-100" id="works-fuel-desk-preview-image"*/}
                                    {/*             src={"images/landing/our-works-fuel-desk-display-image.png"}*/}
                                    {/*             alt="fuel desk"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label className="text-color-white font-size-32">Fuel Desk</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                Fuel Desk is a management dashboard designed for the aviation*/}
                                    {/*                industry to*/}
                                    {/*                manage orders seamlessly.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row mt-3">*/}
                                    {/*    <div className="col-12 col-md-6 position-relative hydromate-preview-image">*/}
                                    {/*        <img className="img-fluid br-10 w-100 h-100" id="hydromate-preview-image"*/}
                                    {/*             src={"images/works/all-works-hydromate-image.png"} alt="hydromate"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white font-size-32 font-weight-700">Hydromate</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                A water reminder and tracking app helps users remember to drink*/}
                                    {/*                water*/}
                                    {/*                according*/}
                                    {/*                to their set schedule and tracks their water intake progress.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div*/}
                                    {/*        className="col-12 col-md-6 position-relative fitness-tracker-preview-image pt-3 pt-md-0">*/}
                                    {/*        <img className="img-fluid br-10 w-100 h-100"*/}
                                    {/*             id="fitness-tracker-preview-image"*/}
                                    {/*             src={"images/works/all-works-gymmate.png"} alt="gym mate"/>*/}
                                    {/*        <div className="position-absolute bottom-0 p-4 m-2">*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white font-size-32 font-weight-700">Gymmate</label>*/}
                                    {/*            <label*/}
                                    {/*                className="text-color-white display-content d-none font-size-16 font-weight-400">*/}
                                    {/*                A game-changing app in the fitness world allows users to schedule*/}
                                    {/*                their gym*/}
                                    {/*                activities, provides a premade schedule, and track the progress.*/}
                                    {/*            </label>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            {/*<div className="row d-flex justify-content-center align-items-center pb-4">*/}
                            {/*    <div className="col-12 pb-4">*/}
                            {/*        <div className="row mt-4">*/}
                            {/*            <div className="col-12">*/}
                            {/*                <div className="d-flex align-items-center justify-content-center mt-5">*/}
                            {/*                    <label id="contact"*/}
                            {/*                           className="font-size-48 font-lato font-weight-700 text-color-black res-font-size-24">*/}
                            {/*                        Contact us*/}
                            {/*                    </label>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="row mt-4 pb-md-4">*/}
                            {/*            <div className="col-md-6 col-12 mt-4">*/}
                            {/*                <form method="POST" id="contact-form"*/}
                            {/*                      action="https://script.google.com/macros/s/AKfycbwMAwOsE7DOKr2lMj3wYh6HRXzQtrMP_FMv8TzlUd1i4K9G0RV8JvbS1iSCB3t-axWB/exec">*/}
                            {/*                    <div>*/}
                            {/*                        <label htmlFor="exampleFormControlNameInput"*/}
                            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Name </label>*/}
                            {/*                        <input type='text' name="Name" required="required"*/}
                            {/*                               className="form-control py-2 br-8" id="exampleFormControlNameInput"*/}
                            {/*                               placeholder="Enter your name"/>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="mt-4">*/}
                            {/*                        <label htmlFor="exampleFormControlPhoneNumberInput"*/}
                            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Phone*/}
                            {/*                            number</label>*/}
                            {/*                        <input type="tel" name="PhoneNumber" className="form-control py-2 br-8 "*/}
                            {/*                               id="exampleFormControlPhoneNumberInput"*/}
                            {/*                               placeholder="Enter your phone number"/>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="mt-4">*/}
                            {/*                        <label htmlFor="exampleFormControlEmailInput"*/}
                            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Email </label>*/}
                            {/*                        <input type="email" name="Email" className="form-control py-2 br-8"*/}
                            {/*                               id="exampleFormControlEmailInput"*/}
                            {/*                               placeholder="Enter your email address"/>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="mt-4">*/}
                            {/*                        <label htmlFor="exampleFormControlTextarea"*/}
                            {/*                               className="form-label font-lato text-color-black font-size-16 font-weight-400">Message</label>*/}
                            {/*                        <textarea className="form-control br-8" name="Message"*/}
                            {/*                                  id="exampleFormControlTextarea"*/}
                            {/*                                  placeholder="Type your message..." rows="5"></textarea>*/}
                            {/*                        <div className="mt-4">*/}
                            {/*                            <div className="d-inline-flex gap-md-5">*/}
                            {/*                                <input type="text" id="captchaInput" className="form-control py-2 br-8"*/}
                            {/*                                       placeholder="Enter Captcha"/>*/}
                            {/*                                    <div className="border-0 p-4 shadow ">*/}
                            {/*                                        <label id="captchaQuestion"*/}
                            {/*                                               className="font-lato text-color-black user-select-none font-size-28 font-weight-700"></label>*/}
                            {/*                                    </div>*/}
                            {/*                            </div>*/}
                            {/*                            <div id="captchaError" className="text-danger mt-2"></div>*/}
                            {/*                        </div>*/}
                            {/*                        <button type="submit"*/}
                            {/*                                className="col-md-4 col-12 button-submit bg-indigo font-lato text-white border-none font-size-24 font-weight-400 mt-4">*/}
                            {/*                            Submit*/}
                            {/*                            <div id="loading-spinner" className="spinner"></div>*/}
                            {/*                        </button>*/}
                            {/*                        <div className="mt-4 p-3" id="msg-container">*/}
                            {/*                            <label id="display-msg" className="text-white"></label>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </form>*/}
                            {/*            </div>*/}
                            {/*            <div className="col-12 col-md-6 p-0 ">*/}
                            {/*                <div className="row mt-md-4">*/}
                            {/*                    <div className="col-6 ">*/}
                            {/*                        <div className="d-flex flex-column mx-2 gap-2 res-display-none">*/}
                            {/*                            <div className="row">*/}
                            {/*                                <div className="col-2 mb-2">*/}
                            {/*                                    <img className="img-fluid" src={"images/landing/icon-mail.png"}*/}
                            {/*                                         alt="mail icon"/>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <div*/}
                            {/*                                className="d-flex flex-column justify-content-center align-items-start">*/}
                            {/*                                <label*/}
                            {/*                                    className="font-size-20 text-color-black font-weight-700">Email</label>*/}
                            {/*                                <label className="font-size-20 font-weight-400 text-color-light-grey">business@7dotss.com</label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                    <div className="col-6">*/}
                            {/*                        <div className="d-flex flex-column mx-2 gap-2 res-display-none ">*/}
                            {/*                            <div className="row">*/}
                            {/*                                <div className="col-2 mb-2">*/}
                            {/*                                    <img className="img-fluid" src={"images/landing/icon-phone.png"}*/}
                            {/*                                         alt="phone logo"/>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                            <div*/}
                            {/*                                className="d-flex flex-column justify-content-center align-items-start">*/}
                            {/*                                <label*/}
                            {/*                                    className="font-size-20 font-weight-700 text-color-black">Phone</label>*/}
                            {/*                                <label*/}
                            {/*                                    className="font-size-20 font-weight-400 text-color-light-grey">+918058406194*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="row pb-4">*/}
                            {/*                        <div className="col-12 mt-4 mt-md-4">*/}
                            {/*                            <iframe*/}
                            {/*                                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3757733731513!2d78.35428197389304!3d17.441719583454766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb938babd6f833%3A0xec8e6a3a616b5334!2s7%20Dots%20Smart%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1703572689456!5m2!1sen!2sin"}*/}
                            {/*                                className="mt-3 br-10" style={{border:"0"}} width="100%" height="180%"*/}
                            {/*                                allowFullScreen=""*/}
                            {/*                                title={"map"}*/}
                            {/*                                loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>*/}
                            {/*    <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <ContactUs/>

                    {/*border*/}
                    <div className="row bg-interface-color font-lato">
                        <div className="col-12 ">
                            <div className="row d-flex justify-content-center border-bottom border-grey-3">
                            </div>
                        </div>
                    </div>
                </div>

                {/*footer section*/}
                <FooterComponent/>
            </div>
        </div>
    )
}

export default ContentWriting;
