import {useEffect, useState} from "react";
import {TailSpin} from "react-loader-spinner";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {getCountryCallingCode} from 'react-phone-number-input'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone,} from "@fortawesome/free-solid-svg-icons";
import countries from 'i18n-iso-countries';
import 'i18n-iso-countries/langs/en.json';
import {useLocation} from 'react-router-dom';
import {logEvent} from 'firebase/analytics';
import {analytics} from "../FirebaseAuthentication/firebase";
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));


const ContactUs = () => {

    const [captchaAnswer, setCaptchaAnswer] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [captchaError, setCaptchaError] = useState('');
    const [captchaQuestion, setCaptchaQuestion] = useState('');
    const [showErrorMsg, setShowErrorMsg] = useState('');
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [value, setValue] = useState();
    const [countryCode, setCountryCode] = useState('91');
    const [countryName, setCountryName] = useState('India');
    const location = useLocation();
    const [formLogCount, setFormLogCount] = useState(0);

    function generateCaptcha() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captcha = '';
        for (let i = 0; i < 6; i++) {
            captcha += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptchaAnswer(captcha);
        setCaptchaQuestion(captcha);
    }

    function validateCaptcha() {
        return captchaInput === captchaAnswer;
    }

    const handelCountryChange = (country) => {
        // console.log(country);
        const code = getCountryCallingCode(country);
        setCountryCode(code);
        const countryName = countries.getName(country, 'en');
        // console.log(countryName);
        setCountryName(countryName);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const form = document.getElementById('contact-form');
        setLoadingBtn(true);
        if (!validateCaptcha()) {
            setLoadingBtn(false);
            setCaptchaError("Incorrect CAPTCHA, please try again.");
            return; // Stop form submission if CAPTCHA is incorrect
        }

        setCaptchaError(''); // Clear previous error messages

        const data = new FormData(form);
        setLoadingBtn(true);
        data.set("PhoneNumber", value);
        data.append('CountryCode', countryCode);
        data.append('CountryName', countryName);
        data.append('origin', window.location.origin);
        const action = e.target.action;
        fetch(action, {
            method: 'POST',
            body: data,
        })
            .then((response) => {
                response.json().then(data => {
                    if (data.result === 'error') {
                        setLoadingBtn(false);
                        setShowErrorMsg('error');
                        form.reset();
                        setCaptchaInput('')
                        setValue();
                        generateCaptcha();
                    }
                })
            })
            .then(() => {
                setLoadingBtn(false);
                setShowErrorMsg('success');
                form.reset();
                setCaptchaInput('')
                setValue();
                generateCaptcha();
            }).catch(() => {
            setLoadingBtn(false);
            setShowErrorMsg('error');
            form.reset();
            setCaptchaInput('')
            setValue();
            generateCaptcha();
        })
    }

    const handleLogEvents = () => {
        setFormLogCount(prevState => prevState + 1)
        if(formLogCount === 1) {
            logEvent(analytics, 'contact_form', {
                page_title: 'Contact Form',
                page_location: window.location.href,
                page_path: location.pathname,
            });
        }
    }

    useEffect(() => {
        generateCaptcha();
        if(setValue && formLogCount < 1) {
            logEvent(analytics, 'contact_form', {
                page_title: 'Contact Form',
                page_location: window.location.href,
                page_path: location.pathname,
            });
        }
    }, []);

    return (
        <div className="row bg-white font-lato d-flex justify-content-center align-items-center pb-4">
            <div className="col-md-10 col-11 pb-4">
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center mt-md-5 mt-2">
                            <label id="contact"
                                   className="font-size-48 font-lato font-weight-700 text-color-black res-font-size-24">
                                Contact us
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-md-4 pb-md-4">
                    <div className="col-md-6 col-12 mt-4">
                        <form method="POST" id="contact-form" onSubmit={onFormSubmit}
                              action="https://script.google.com/macros/s/AKfycbwMAwOsE7DOKr2lMj3wYh6HRXzQtrMP_FMv8TzlUd1i4K9G0RV8JvbS1iSCB3t-axWB/exec">
                            <div>
                                <label htmlFor="exampleFormControlNameInput"
                                       className="form-label font-lato text-color-black font-size-16 font-weight-400">Name </label>
                                <input type='text' name="Name" required="required"
                                       className="form-control py-2 br-8"
                                       id="exampleFormControlNameInput"
                                       onChange={handleLogEvents}
                                       placeholder="Enter your name"/>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="exampleFormControlPhoneNumberInput"
                                       className="form-label font-lato text-color-black font-size-16 font-weight-400">Phone
                                    number</label>
                                <PhoneInput
                                    placeholder="Enter your phone number"
                                    className={"form-control custom-phone-input"}
                                    value={value}
                                    initialValueFormat={"national"}
                                    limitMaxLength={true}
                                    defaultCountry={"IN"}
                                    onCountryChange={handelCountryChange}
                                    focusInputOnCountrySelection={true}
                                    onChange={setValue}/>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="exampleFormControlEmailInput"
                                       className="form-label font-lato text-color-black font-size-16 font-weight-400">Email</label>
                                <input type="email" name="Email" className="form-control py-2 br-8"
                                       id="exampleFormControlEmailInput"
                                       onChange={handleLogEvents}
                                       placeholder="Enter your email address"/>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="exampleFormControlTextarea"
                                       className="form-label font-lato text-color-black font-size-16 font-weight-400">Message</label>
                                <textarea className="form-control br-8" name="Message"
                                          id="exampleFormControlTextarea"
                                          onChange={handleLogEvents}
                                          placeholder="Type your message..." rows="5"></textarea>
                            </div>
                            <div className="mt-4">
                                <div className="d-inline-flex gap-md-5 gap-3">
                                    <input type="text" id="captchaInput" value={captchaInput}
                                           onChange={(e) => setCaptchaInput(e.target.value)}
                                           className="form-control py-2 br-8"
                                           placeholder="Enter Captcha"/>
                                    <div className="border-0 p-4 shadow ">
                                        <label id="captchaQuestion"
                                               className="font-lato text-color-black user-select-none font-size-28 font-weight-700">{captchaQuestion}</label>
                                    </div>
                                </div>
                                {captchaError &&
                                    <div id="captchaError" className="text-danger mt-2">{captchaError}</div>}

                            </div>
                            <button type="submit" id="btn-form-submit" disabled={loadingBtn}
                                    className="col-md-4 col-12 button-submit bg-indigo font-lato text-white border-none font-size-24 font-weight-400 mt-4">
                                {loadingBtn ? <TailSpin width={30} height={30}
                                                        color={"#FFFFFF"}/> : 'Submit'}
                            </button>
                            <div
                                className={`${showErrorMsg === 'error' ? 'error-msg-display-container mt-4 p-3 ' : ''} ${showErrorMsg === 'success' ? 'success-msg-display-container mt-4 p-3 ' : ''}`}
                                id="msg-container">
                                {showErrorMsg === 'success' &&
                                    <label id="display-msg" className="text-white">Your form was submitted
                                        successfully. Thank you, will get back to you soon!</label>}
                                {showErrorMsg === 'error' &&
                                    <label id="display-msg" className="text-white">There was an error while
                                        submitting the form. Please try again later.</label>}
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-md-6 p-0 ">
                        <div className="row mt-md-4">
                            <div className="col-6 d-md-block d-none ">
                                <div className="d-flex flex-column mx-2">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-md-2 col-4">*/}
                                    {/*        <img className="img-fluid" src={"/images/landing/icon-mail.png"}*/}
                                    {/*             alt="mail icon"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={"mb-2"}>
                                        <FontAwesomeIcon icon={faEnvelope} size={'lg'}/>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-start">
                                        <label
                                            className="font-size-20 text-color-black font-weight-700">Email</label>
                                        <label
                                            className="font-size-20 font-weight-400 text-color-light-grey">business@7dotss.com</label>
                                    </div>
                                </div>

                            </div>
                            <div className="col-6 d-md-block d-none">
                                <div className="d-flex flex-column mx-2 res-display-none ">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-md-2 col-4 mb-2">*/}
                                    {/*        <img className="img-fluid" src={"/images/landing/icon-phone.png"}*/}
                                    {/*             alt="phone logo"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={"mb-2"}>
                                        <FontAwesomeIcon icon={faPhone} size={'lg'}/>
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-start">
                                        <label
                                            className="font-size-20 font-weight-700 text-color-black">Phone</label>
                                        <label
                                            className="font-size-20 font-weight-400 text-color-light-grey">+918058406194
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3 mt-md-4">
                                <iframe
                                    src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3757733731513!2d78.35428197389304!3d17.441719583454766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb938babd6f833%3A0xec8e6a3a616b5334!2s7%20Dots%20Smart%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1703572689456!5m2!1sen!2sin"}
                                    className="mt-3 br-10" style={{border: "0"}} width="100%" height="180%"
                                    allowFullScreen=""
                                    title={"map"}
                                    loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>
            <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>
            <div className="col-12 pb-4 mb-4 d-block d-md-none"></div>
        </div>
    )
}

export default ContactUs;
