import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import NavbarComponent from "../NavbarComponent/navbarComponent";
import FooterComponent from "../FooterComponent/footerComponent";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS} from '@contentful/rich-text-types';
import {createClient} from 'contentful';
import {InView} from "react-intersection-observer";

const EachBlogPage = () => {

    const params = useParams();
    const id = params.id;
    // console.log(id);
    const client = createClient({
        space: 'pr1iuhatqedc',
        accessToken: 'wMmCg3eBGgZLKHpTMD82274BkSm_hkcKO5Dl1it0mDc',
    });
    const [blogData, setBlogData] = useState([]);
    // const [blogsDataItems, setBlogsDataItems] = useState([]);
    const [nextBlogsData, setNextBlogs] = useState([]);
    const [blogsData, setBlogsData] = useState();
    const [blogContent, setBlogContent] = useState([]);
    const [pathBolgData, setPathBlogData] = useState([]);
    const [activeId, setActiveId] = useState('Introduction');
    const [assetMap, setAssetMap] = useState({});

    const options = {
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => {
                const headingText = node.content[0].value; // Get the heading text
                const headingId = headingText; // Generate a slug ID
                return <InView as={"h3"} id={headingId}  rootMargin={"-2% 0% -98% 0%"} onChange={(inView,entry) => {
                    // console.log(entry);
                    // console.log(inView);
                    if (inView) {
                        setActiveId(headingId)
                    }
                }} className={"font-size-32 mt-4"}>{children}</InView>;
            },
            [BLOCKS.HEADING_4]: (node, children) => {
                const headingText = node.content[0].value; // Get the heading text
                return <div className={"h4 text-center"}>{children}</div>;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const assetId = node.data.target.sys.id;
                const asset = assetMap[assetId];

                if (asset) {
                    const imageUrl = asset.fields.file.url;
                    const altText = asset.fields.title || 'Embedded image';
                    return <img src={imageUrl} alt={altText} className="img-fluid w-100 br-10 rich-text-image"/>;
                }
                return
            },
        },
    };



    const getImageUrlByBlogImageId = (blogImageId, assets) => {
        const matchingAsset = assets.find(asset => asset.sys.id === blogImageId);
        return matchingAsset.fields.file.url;
    };

    const getPublishedDate = (pubDate) => {
        const date = new Date(pubDate);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', {month: 'short'});
        const year = date.getFullYear();

        return `${month} ${day} ${year}`
    }

    const fetchGetAllBlogsData = async () => {
        try {
            const allBlogsData = await client.getEntries();
            setBlogsData(allBlogsData);
            // setBlogsDataItems(allBlogsData.items);
            getNextBlogs(id, allBlogsData.items);
            // console.log(allBlogsData.items);
        } catch (e) {
            console.log(e);
        }
    }

    const getNextBlogs = (selectedSlug, blogList) => {
        // Find the index of the selected blog
        const selectedIndex = blogList.findIndex(blog => blog.fields.blogSlug === selectedSlug);
        console.log(selectedIndex)
        // Filter out the selected blog
        // const filteredBlogs = blogList.filter(blog => blog.fields.blogSlug !== selectedSlug);
        // console.log("filter blogs", filteredBlogs);

        // Get the next 3 blogs starting from the selected blog index + 1
        const nextBlogs = [];
        for (let i = 1; i <=2; i++) {
            // Use modulo to wrap around the list if we exceed its length
            const nextIndex = (selectedIndex + i) % blogList.length;
            console.log("next index",nextIndex)
            nextBlogs.push(blogList[nextIndex]);
        }
        console.log("nextBlogs", nextBlogs)
        setNextBlogs(nextBlogs);

        return nextBlogs;
    };


    useEffect(() => {
        const fetchGetBlogsData = async () => {
            try {
                const data = await client.getEntries({
                    content_type: 'blogs',
                    'fields.blogSlug[match]': encodeURIComponent(id)
                });
                setBlogData(data);
                setPathBlogData(data.items);
                data.items.map(each => {
                    if (each.fields.blogContent) {
                        setBlogContent(each.fields.blogContent.content)
                        // console.log(each.fields.blogContent)
                    }
                    return []
                });

                // const imageData = await axios.get(baseUrl+`&fileds.blogName=${encodeURIComponent()}`)
            } catch (e) {
                console.log(e);
            }
        }
        document.querySelector("body").scrollTo(0, 0);
        fetchGetBlogsData();
        fetchGetAllBlogsData();
    }, [id]);

    useEffect(() => {
        const fetchAssets = async () => {
            const assetIds = blogContent
                .filter((node) => node.nodeType === 'embedded-asset-block')
                .map((node) => node.data.target.sys.id);

            // console.log(assetIds);

            const fetchedAssets = await Promise.all(
                assetIds.map(async (id) => {
                    const asset = await client.getAsset(id);
                    return {id, asset};
                })
            );

            // console.log(fetchedAssets);

            const assetMap = fetchedAssets.reduce((acc, {id, asset}) => {
                acc[id] = asset;
                return acc;
            }, {});

            setAssetMap(assetMap);
        };
        fetchAssets();
    }, [blogData]);

    useEffect(() => {
        document.querySelector("body").scrollTo(0, 0);
    }, [id]);

    return (
        <div className="container-fluid">
            <div id="works" className="row bg-interface-color justify-content-center align-items-center font-lato">
                <div className="col-12">
                    {/*navbar*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <NavbarComponent/>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        {pathBolgData.map((each, index) => (
                            <div className="col-12" key={index}>
                                <div className="row mt-4 justify-content-between align-items-center">
                                    <div
                                        className={"col-2 p-0 d-flex flex-grow-1 align-self-start align-self-md-center"}>
                                        <img src={'/images/blogs/blogs-landing-left-image.png'}
                                             className={"img-fluid object-fit-cover h-100"} alt={'blog-img-left'}/>
                                    </div>
                                    <div className="col-8 pt-2 pt-md-4 px-0 mt-4 d-flex flex-column">
                                        <div className={"mb-4 w-100 d-flex justify-content-center"}>
                                            <label
                                                className={"font-size-14 font-weight-700 font-lato text-color-black bg-blog-tag-name px-2 py-1 br-4"}>
                                                {each.metadata.tags ? each.metadata.tags[0].sys.id.charAt(0).toUpperCase() + each.metadata.tags[0].sys.id.slice(1).toLowerCase() : ''}
                                            </label>
                                        </div>
                                        <div
                                            className="d-flex flex-column align-items-center text-center justify-content-center flex-grow-1">
                                            <label className="font-size-48 font-weight-700 font-georgia">
                                                {each.fields.blogLandingHeading}
                                            </label>
                                        </div>
                                        <div className={"row justify-content-center"}>
                                            <div className={"mt-4 col-md-8 col-12 "}>
                                                <label
                                                    className="text-align-center font-size-18 font-weight-400 text-color-464646 res-flex-column res-text-align-center">
                                                    {each.fields.blogDescription}
                                                </label>
                                                <div className={"d-flex justify-content-center w-100 mt-4"}>
                                                    <label>{each.fields.blogPublishedDate ? getPublishedDate(each.fields.blogPublishedDate) : ''}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-2 p-0 align-self-end align-self-md-center"}>
                                        <img src={'/images/blogs/blogs-landing-right-image.png'} className={"img-fluid"}
                                             alt={'blog-img-left'}/>
                                    </div>
                                </div>
                                {/*blog landing image*/}
                                <div className={"row justify-content-center mt-md-5 pt-2 mt-4"}>
                                    <div className={"col-10"}>
                                        <div className={"w-100"}>
                                            <img
                                                src={`${each.fields.blogLandingImage ? `${getImageUrlByBlogImageId(each.fields.blogLandingImage.sys.id, blogData.includes.Asset)}` : '/images/blogs/default-blog-image.png'}`}
                                                alt={"blog"} className={"img-fluid w-100 br-10"}/>
                                        </div>

                                    </div>
                                </div>

                                <div className={"row justify-content-center my-md-5 py-2 my-4"}>
                                    <div className={"col-md-10 col-11 px-0"}>
                                        <div className={"row justify-content-between blog-details-container"}>
                                            <div className={"col-4 blog-table-content d-none d-md-block "}>
                                                <div className={"w-100 border-grey-3 p-4 br-10 flex-shrink-0"}>
                                                    <label
                                                        className={"font-size-24 font-weight-700 text-color-black font-lato"}>Table
                                                        of contents</label>
                                                    {blogContent.map((eachContent, index) => (
                                                        <div key={index}>
                                                            {eachContent.nodeType === 'heading-3' && (
                                                                <div className={"d-flex flex-column gap-3 mt-4"}>
                                                                    {eachContent.content.map((item, index) => (
                                                                        <button key={index}
                                                                                onClick={() => {
                                                                                    document.getElementById(item.value)?.scrollIntoView({
                                                                                        behavior: 'smooth', // Smooth scrolling
                                                                                        block: 'start', // Aligns the element to the start of the visible area
                                                                                    });
                                                                                    setActiveId(item.value);
                                                                                }}
                                                                                className={`border-0 bg-transparent font-size-16 font-weight-400 text-start ${activeId === item.value ? 'text-indgo' : 'text-color-grey-dark'} p-0`}>
                                                                            {item.value}
                                                                        </button>
                                                                    ))}
                                                                </div>)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={"col-md-7 col-12 p-md-0 font-lato"}>
                                                {pathBolgData.map(data => (
                                                    documentToReactComponents(data.fields.blogContent, options)
                                                ))}
                                                {/*{blogContent.map((eachContent, index) => (*/}
                                                {/*    <div className={"w-100 blog-content-container"} key={index}>*/}
                                                {/*        {eachContent.nodeType === 'heading-3' && (*/}
                                                {/*            eachContent.content.map(item => (<label*/}
                                                {/*                className={"font-size-32 font-weight-700 text-color-black my-4"}*/}
                                                {/*                id={`${item.value}`}>{item.value}</label>)))}*/}
                                                {/*        {eachContent.nodeType === 'embedded-asset-block' && (*/}
                                                {/*            <img*/}
                                                {/*                src={`${getImageUrlByBlogImageId(eachContent.data.target.sys.id, blogData.includes.Asset)}`}*/}
                                                {/*                alt={"blog"} className={"img-fluid w-100 br-10"}/>*/}
                                                {/*        )}*/}
                                                {/*        {eachContent.nodeType === 'paragraph' && (eachContent.content.map(item => (*/}
                                                {/*            <label*/}
                                                {/*                className={`font-size-18 pb-3 ${item.marks.map(type => type.type === 'bold' ? 'font-weight-700 text-color-black' : 'font-weight-400 text-color-grey-dark')}`}>{item.value}</label>)))}*/}
                                                {/*    </div>*/}
                                                {/*))}*/}
                                            </div>
                                        </div>
                                        <div className={"row mt-md-5 mt-4 pt-2"}>
                                            <div className={"mb-md-5 mb-4 col-12"}>
                                                <label
                                                    className={"text-color-black font-weight-700 font-size-48 font-lato"}>See
                                                    more</label>
                                            </div>
                                            <div className={"col-12 d-flex justify-content-start flex-wrap gap-5"}>
                                                {nextBlogsData.map((blogs, index) => (
                                                    <div className={"col-12 col-md-4"} key={index}>
                                                        <Link to={`/blogs/${blogs.fields.blogSlug}`}>
                                                            <div>
                                                                <img
                                                                    src={`${blogs.fields.blogImage ? `${getImageUrlByBlogImageId(blogs.fields.blogImage.sys.id, blogsData.includes.Asset)}` : '/images/blogs/default-blog-image.png'}`}
                                                                    alt={"blog"} className={"img-fluid w-100 br-10"}/>
                                                            </div>
                                                            <div className={"mt-4"}>
                                                                <label
                                                                    className={"font-size-14 font-weight-700 font-lato text-color-black bg-blog-tag-name px-2 py-1 br-4"}>
                                                                    {blogs.metadata.tags ? blogs.metadata.tags[0].sys.id.charAt(0).toUpperCase() + blogs.metadata.tags[0].sys.id.slice(1).toLowerCase() : ''}
                                                                </label>
                                                            </div>
                                                            <div className={"mt-3"}>
                                                                <label
                                                                    className={"font-size-24 font-weight-700 font-lato text-color-black"}>
                                                                    {blogs.fields.blogHeading}
                                                                </label>
                                                            </div>
                                                            <div className={"mt-2"}>
                                                                <label
                                                                    className={"font-size-16 font-weight-400 font-lato text-color-grey-dark"}>
                                                                    {blogs.fields.blogPublishedDate ? getPublishedDate(blogs.fields.blogPublishedDate) : ''}
                                                                </label>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/*border*/}
                    <div className="row bg-interface-color font-lato">
                        <div className="col-12 ">
                            <div className="row d-flex justify-content-center mt-4 border-bottom border-grey-3">
                            </div>
                        </div>
                    </div>
                </div>

                {/*footer section*/}
                <FooterComponent/>
            </div>
        </div>
    )
}

export default EachBlogPage;
